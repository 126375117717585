.map-box {
  position: relative;
  margin-bottom: 6.4rem;
  padding: 0;
}
.general-box {
  background: #ffffff;
  opacity: 0.93;
  position: absolute;
  top: 60px;
  left: 20px;
  padding: 24px;
  height: 300px;
  max-height: 300px;
  width: 320px;
  overflow: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  /* max-width: 14vw; */
}

.general-title {
  color: #666666;
  font-weight: 500;
  font-size: 14px;
  height: 24px;
  margin-top: 6px;
  text-transform: none;
}
.general-value,
.general-value a {
  color: #333333;
  font-weight: bolder;
  font-size: 16px;
  text-transform: uppercase;
  min-height: 25px;
  margin-bottom: 5px;
  /* font-weight: 500; */

}

@media (min-width: 2400px) {
  .general-title {
    margin-top: 6px;
    font-size: 13px;
  }

  .general-value,
  .general-value a {
    margin-top: 6px;
    font-size: 14px;
  }
}

@media (max-width: 42em) {
  .general-box {
    width: 80%;
  }
}

.general-value .normal {
  text-transform: none;
  font-style: italic;
}

/* .general-box {
  height: 100%;
  border: 2px dashed #b5b3b3;
  padding: 10px;
} */

#map-mode{
  position: absolute;
  top: 10px;
  right: 80px;
  box-shadow: 0 1.2rem 3.2rem rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 5px;
  background: #ffffff;
}

#map-mode .MuiFormControlLabel-label{
  font-size: 16px;
}
