$fontInter: 'Inter';
$fontNormal: normal;
$white: #ffffff;
$black: #000000;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.top {
  padding: 1rem 1rem !important;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.organisation-content {
  display: flex;
  // grid-template-columns: 3fr 27fr 70fr;
}

.info-icon {
  margin-left: 0.6rem;
}

.input-group-org {
  padding: 0px;
  width: 35%;
  height: 40px;
  input {
    margin-bottom: 0;
    width: 100%;
  }
}

.input-group-org .input-icon-org {
  border-color: #89d8ef !important;
  padding: 10px 0px 10px 0px;
  gap: 8px;
  background-color: white;
  border-radius: 10px 0px 0px 10px;
}

.input-group-org .input-icon-org .icon-search-org {
  height: 17px;
  margin-left: 15px;
  margin-right: 3px;
}

.input-group-org .input-group-text-org {
  padding: 0;
  margin: 0;
  border-left: 0;
  height: 100%;
  width: 80px;
  color: white;
  opacity: 1;
  background: linear-gradient(118.55deg, #42bbff -12.3%, #4085ec 110.19%);
  border-radius: 0px 10px 10px 0px;
  font-size: 1.4rem;
  font-weight: 600;
}

.menu {
  background-color: #e4ebf3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-icon-container {
  padding: 1.6rem;
  border-radius: 8px;
  cursor: pointer;
}

.menu-icon {
  color: #2770f1;
}

.filter {
  padding: 2rem;
  max-width: 35rem;
  .filter-btn-container {
    width: 100%;
    background-color: white;
    padding: 2rem;
    border-radius: 0 0 8px 8px;
  }
}

.selected {
  background-color: #fff;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.content {
  font-family: $fontInter;
  font-style: $fontNormal;
  background-color: #f5f8ff;
  padding: 2rem;
  flex-grow: 1;
  min-height: 100vh;
  width: 80%;
}

.content h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
}

.content .filter h5 {
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 18px;
  color: #333333;
}

.content .filter label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}
.content .bar {
  background-color: $white;
  border-color: #c4e2f8 !important;
}
.content .bar button {
  height: 81.59px;
  border-radius: 0%;
  border-color: #c4e2f8 !important;
  --bs-btn-bg: $white;
}
.content .bar button span {
  color: #27c1f1;
}
.content .bar .related div {
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  // width: 210px;
}
.content .bar .related h6 {
  font-family: $fontInter;
  font-style: $fontNormal;
  font-size: 13px;
  color: #666666;
}
.content .bar .related label {
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.content .sort h5 {
  margin-bottom: 0;
  font-size: 15px;
  color: #666666;
}

.content .sort select {
  margin-right: 2px;
  cursor: pointer;
  font-size: 15px;
  width: 150px;
  background-color: transparent;
  color: #27c1f1;
}
.content .sort button span {
  color: #27c1f1;
}

#item-organizations {
  border-style: hidden;
  padding: 20px;
  background-color: $white;
}

#item-organizations:hover {
  border-style: solid;
  padding: 19px;
  border-color: #27c1f1;
  box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
}

#item-organizations .infor .img {
  width: 100px;
  height: 100px;
  padding: 12px;
  margin-bottom: 24px;
}
#item-organizations .infor .img img {
  object-fit: contain;
  height: 100%;
  width: -webkit-fill-available;
}

.item-expertise {
  border-radius: 1000px;
  background: #dff3ff;
  padding: 6px 12px;
  gap: 10px;
}

.detail-info-2-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.item-expertise label {
  cursor: pointer;
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #0a2a63;
}

.infor .name {
  font-family: $fontInter;
  font-style: $fontNormal;
  cursor: pointer;
  color: #0a2a63;
  font-size: 17px;
  font-weight: 700 !important;
  margin-bottom: 16px;
  text-transform: capitalize;
  text-decoration: none;
}

.infor .name:hover {
  text-decoration: underline;
  color: #0e4dba;
}

.infor .org_des {
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 16px;
}

.infor .note {
  height: 80px;
  background-color: $white;
  padding: 0px 20px;
  margin-left: 30px;
  border-color: #27c1f1;
}

.infor .icon-note {
  font-size: 17px;
  color: #27c1f1;
}

.infor .text-title {
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
}

.infor .text-note {
  width: 150px;
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #666666;
}

.footer-org .input-show {
  width: 160px;
  border-color: #27c1f1 !important;
  padding: 12px 16px;
}

.footer-org .btn-show {
  width: 160px;
  border-color: #27c1f1 !important;
  padding: 12px 16px;
}

// .footer-org .text-show:hover{
//   cursor: pointer;
//   font-family: $fontInter;
//   font-style: $fontNormal;
//   font-weight: 400;
//   font-size: 13px;
//   line-height: 16px;
//   color: $white;
// }

// .footer-org .icon-results:hover{
//   color: $white;
//   font-size: 17px;
// }

.footer-org .text-show {
  cursor: pointer;
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
}

.footer-org .icon-results {
  color: #27c1f1;
  font-size: 17px;
}

.footer-org .btn-up {
  width: 48px;
  height: 48px;
  padding: 0px 16px;
  gap: 8px;
  background: linear-gradient(118.55deg, #2770f1 -12.3%, #27c1f1 110.19%);
  box-shadow: 0px 2px 8px rgba(39, 112, 241, 0.35);
  border-radius: 8px;
  border-style: hidden;
}

.footer-org .btn-up:hover {
  border-style: solid;
  border-color: $white !important;
}

.footer-org .btn-up span {
  color: $white;
}

.footer-org .page-number .page-item {
  border-color: #c4eefb !important;
  font-weight: 400;
  color: $black;
  font-size: 13px;
  line-height: 16px;
  background: $white;
  font-family: $fontInter;
  font-style: $fontNormal;
  border-radius: 0;
  padding: 12px 16px;
  gap: 8px;
  width: 40px;
  height: 40px;
}

.footer-org .page-number .page-item:hover {
  background: linear-gradient(118.55deg, #2770f1 -12.3%, #27c1f1 110.19%);
  color: $white !important;
}

.footer-org .page-number .is-active-org {
  background: linear-gradient(118.55deg, #2770f1 -12.3%, #27c1f1 110.19%);
  color: $white !important;
}

@media (max-width: 65em) {
  .menu {
    display: none;
  }

  .filter {
    max-width: none;
    width: 100vw !important;
    font-size: 13px;
    filter: drop-shadow(0px 4px 16px rgba(25, 68, 144, 0.25));
    border-radius: 8px;
  }

  .filter-header {
    background-color: #dff3ff;
    padding: 0 16px;
    border-radius: 8px 8px 0 0;
    p {
      margin-bottom: 0;
    }
  }

  .organisation-content {
    display: block;
  }

  .filter-main {
    display: flex;
    // flex-wrap: wrap;
    // width: 100%;
    // flex: 1;
  }

  .content {
    width: 100%;
  }
  .exp-item .exp-info-right {
    flex: none;
    padding-top: 10px;
    border-top: 1px solid #d7e7f3;
  }
  .exp-item .exp-info-left {
    border-right: none !important;
  }
  .exp-item .exp-info-left {
    flex: auto;
  }

  .mb-mobile {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 979px) {
  .input-group-org {
    width: 344px;
  }
  .input-group-org input {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
  .input-group-org .input-group-text-org {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }
}

@media (max-width: 42em) {
  #org-list .bar .related h6 {
    font-size: 1rem !important;
  }
  #item-organizations .infor .img {
    margin: 24px auto;
    width: 100px;
  }
  .input-group-org {
    width: 344px;
  }
  .mobile-h3 {
    font-size: 16px !important;
    line-height: 19.36px !important;
  }
  .infor .note {
    height: auto;
  }
  .org-avatar-col-3 {
    width: 25% !important;
  }
  .org-avatar-col-9 {
    width: 75% !important;
  }
  .infor-expert {
    height: 110px !important;
  }
  .input-group-org input {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
  .input-group-org .input-group-text-org {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }
  .filter-main {
    flex-direction: column;
  }
  .org-chart {
    display: block !important;
  }
  .org-chart > div {
    width: 100% !important;
    margin-bottom: 20px;
  }
}
