#header-content {
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: -680px;
  padding-left: 100px;
  padding-right: 100px;
}
#header-content h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 39px;
  align-self: stretch;
}
#header-content h5 {
  width: 900px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
}
#search-container {
  margin-top: 80px;
  width: 800px;
  height: 288px;
  background: linear-gradient(261.49deg, #154a89 -0.5%, #0e3c82 101.33%);
  opacity: 0.95;
  box-shadow: 0px 8px 24px rgba(25, 68, 144, 0.25);
  border-radius: 8px;
}
#search-container p {
  padding-top: 35px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #ffffff;
  height: 60px;
}
#search-container .navbar-nav {
  padding-top: 8px;
  width: 430px;
}
.text-white {
  color: white;
}
.outline-none {
  outline: none;
}
#search-container .navbar-nav .nav-item {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  font-family: 'Inter', sans-serif !important;
}
#search-container .nav-link {
  width: 120px ;
  font-weight: 500 ;
  font-size: 14px;
}
#search-container .nav-item:hover .nav-link {
  font-weight: 700 !important;
  border-bottom: 2px solid white;
  font-weight: bold !important;
}
#search-container .nav-item .nav-active {
  color: #eebd40 !important;
  font-weight: 700 !important;
  border-bottom: 2px solid #eebd40 !important;
  font-family: 'Inter', sans-serif !important;
}
#input-group {
  margin-top: 30px;
  height: 150px;
  width: 100%;
}
#input-group .input-group {
  height: 55px;
  width: 606px;
}
#input-group .icon-search {
  margin-left: 25px;
  margin-right: 10px;
}
#input-icon {
  background-color: white;
  border-radius: 10px 0px 0px 10px;
}
#input-icon input {
  font-size: 16px;
  width: 100%;
  margin-bottom: 0;
}
#text-input-group {
  font-size: 16px;
  width: 121px;
  color: white;
  opacity: 1;
  background: linear-gradient(118.55deg, #42bbff -12.3%, #4085ec 110.19%);
  border-radius: 0px 10px 10px 0px;
  font-size: 1.6rem;
  font-weight: 600;
}

#experts {
  top: 1000px;
  width: 100%;
  margin-bottom: 6.4rem;
}
#header-experts {
  border-radius: 5px;
  width: 100%;
  background-color: #f0f6ff;
  padding: 16px 24px;
  gap: 10px;
}
#header-experts .icon-school {
  color: #0a2a63;
}
#header-experts .icon-school label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
#header-experts #btn-controls span {
  color: #27c1f1;
}
#header-experts #btn-controls span:hover {
  color: rgb(0, 0, 255);
}
#header-experts #btn-controls .btn-control {
  --bs-btn-border-radius: 0rem !important;
  background-color: white;
}
#btn-controls #filter {
  transition: all 0.5s ease-in-out;
}
#btn-controls h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
}
#experts-bg {
  opacity: 1;
  width: 100%;
  height: 660px;
  background-size: cover;
  border-radius: 0 0 8px 8px;
}
#experts-bg .experts-content {
  height: 92%;
  width: 100%;
}
#experts-bg .experts-content .btn-back {
  padding: 0;
  margin-left: 50px;
  margin-top: 72px;
}
#experts-bg .experts-content .btn-forward {
  padding: 0;
  margin-right: 50px;
  margin-top: 72px;
}
#experts-bg .experts-content button span {
  font-size: 60px;
  color: white;
}
#experts-bg .experts-content button span:hover {
  color: #2989e9;
  transform: scale(1.1);
}
#experts-bg .nav-pages {
  height: 6%;
  width: 100%;
}
#experts-bg .nav-pages span {
  font-size: 13px;
  color: white;
}
#experts-bg .nav-pages button:hover {
  transform: scale(1.1);
}
#experts-bg .currentPage {
  color: #27c1f1 !important;
}
#expert {
  cursor: pointer;
  background-color: white;
  border-radius: 9px 9px 8px 8px;
  margin-top: 100px;
  width: 270px;
  height: 423.4px;
}
#expert:hover {
  transform: scale(1.01);
}
.avatar {
  z-index: 1;
  position: absolute;
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background-color: #53575d;
  margin-top: 30px;
  padding: 7px;
}
.avatar img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#expert #name-expert {
  border-radius: 8px 8px 0px 0px;
  height: 89px;
  background: linear-gradient(118.55deg, #2770f1 -12.3%, #27c1f1 110.19%);
}
#expert #name-expert h6 {
  margin-bottom: 15px;
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
#expert #infor-expert {
  padding: 1.5rem;
  font-family: 'Inter';
  font-style: normal;
}
#expert #infor-expert span {
  color: #666666;
  font-size: 18px;
}
#expert #infor-expert label {
  color: #42bbff;
  font-weight: 500;
  font-size: 12.6px;
  line-height: 20px;
}
#expert #infor-expert .text-expert-infor {
  color: #666666;
  font-weight: 500;
  font-size: 12.6px;
  line-height: 20px;
}
#expert hr {
  color: #2989e9;
}
#expert #explain {
  font-family: 'Inter';
  font-style: normal;
  line-height: 20px;
}
#expert #explain h6 {
  font-weight: 600;
  font-size: 12.6px;
  color: #000000;
}
#expert #explain ul {
  list-style: none;
}
#expert #explain ul li {
  font-weight: 400;
  font-size: 12.6px;
  color: #666666;
}
.is-active {
  transform: scale(1.12);
}
.is-active-image-avatar {
  transform: scale(1.12);
  margin-top: -3px !important;
}
.is-active:hover {
  transform: scale(1.14) !important;
}
.box-shadow {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(21, 74, 137, 0.1);
  border-radius: 8px;
}

footer nav .nav-link {
  width: 70px !important;
  font-weight: 500 !important;
  color: white !important;
  font-size: 14px;
}

.MuiPagination-root li > button {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-right: 1px solid #d7e7f3;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
}
.MuiPagination-root li > button.Mui-selected {
  background: linear-gradient(118.55deg, #2770f1 -12.3%, #27c1f1 110.19%);
  font-weight: 700;
  color: #ffffff;
}

@media screen and (max-width: 480px){
  #expert{
    width: 301px;
  }  
  #experts-bg .experts-content .btn-back{
    display: none;
  }
  #experts-bg .experts-content .btn-forward{
    display: none;
  }
  #header-content{
    margin-top: -360px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #header-content h1{
    font-size: 14px;
    line-height: 22px;
  }
  #header-content h5{
    font-size: 13px;
    line-height: 20px;
  }
  #search-container{
    width: 342px;
    height: 204px;
    margin-top: 10px;
  }
  #search-container p{
    margin-top: 0px;
    margin-bottom: -10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  #search-container .nav-link{
    font-size: 12px;
    line-height: 15px;
    width: auto;
  }
  #search-container .navbar-nav{
    padding-top: 0px;
  }
  #input-group .input-group{
    flex-wrap: nowrap;
  }
  #input-icon{
    width: 236px;
    height: 44px;
  }
  #input-group{
    margin-top: 25px;
  }
  #text-input-group{
    font-size: 13px;
    line-height: 16px;
    width: 75px;
    height: 44px;
  }
  #input-group .icon-search{
    height: 16px;
    margin-left: 15px;
    margin-right: 15px;
  }
  #input-icon input{
    font-size: 13px;
    line-height: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 979px){
  #expert{
    width: 208.58px;
  }
  #experts-bg .experts-content .btn-back{
    display: none;
  }
  #experts-bg .experts-content .btn-forward{
    display: none;
  }
  #expert #name-expert h6{
    font-size: 13.9054px;
    line-height: 15px;
  }
  #expert #infor-expert .text-expert-infor{
    font-size: 9.73378px;
    line-height: 15px;
  }
  #expert #infor-expert label{
    font-size: 9.73378px;
    line-height: 15px;
  }
  #expert #explain h6{
    font-size: 10px;
  }
  #expert #explain ul li{
    font-size: 9.73378px;
    line-height: 15px;
  }
}

@media screen and (min-width: 480px) and (max-width: 979px) {
  #header-content{
    margin-top: -760px;
    padding-left: 20px;
    padding-right: 20px;
  }
  #header-content h1{
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 24px;
  }
  #header-content h5{
    width: 542px;
    font-size: 13px;
    line-height: 20px;
  }
  #search-container{
    width: 728px;
    height: 236px;
    margin-top: 20px;
  }
  #search-container p{
    margin-top: 0px;
    margin-bottom: -10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  #search-container .nav-link{
    font-size: 12px;
    line-height: 15px;
    width: auto;
  }
  #search-container .navbar-nav{
    padding-top: 0px;
  }
  #input-group .input-group{
    flex-wrap: nowrap;
  }
  #input-icon{
    width: 236px;
    height: 44px;
  }
  #input-group{
    margin-top: 35px;
  }
  #text-input-group{
    font-size: 13px;
    line-height: 16px;
    width: 75px;
    height: 44px;
  }
  #input-group .icon-search{
    height: 16px;
    margin-left: 15px;
    margin-right: 15px;
  }
  #input-icon input{
    font-size: 13px;
    line-height: 16px;
  }
}

#root {
  background-color: #F5F8FF;
  background-image: url(/public/images/background/pattern.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}