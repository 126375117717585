footer {
  padding-top: 0.5em;
  text-align: center;
  /* height: 127px; */
  width: 100%;
  background: linear-gradient(261.49deg, #154A89 -0.5%, #0E3C82 101.33%);
  opacity: 0.95;
  box-shadow: 0px -2px 8px rgba(25, 68, 144, 0.25);   
}
.footer-border-top{
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.footer-label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}
.logo{
  cursor: pointer;
  width: 200px;
}
.icon{
  cursor: pointer;
}
.icon:hover{
  box-shadow: 0px -2px 8px rgba(25, 68, 144, 0.25);   
  transform: scale(1.1);
}

@media screen and (max-width: 480px){
  footer{
    height: 119px;
  }
  footer .footer-container{
    justify-content: center !important;
  }
  footer .footer-logo{
    display: none;
  }
  footer .footer-nav{
    display: none;
  }
}

@media screen and (min-width: 480px) and (max-width: 979px){
  footer{
    height: 119px;
  }
  footer .footer-container{
    justify-content: center !important;
  }
  footer .footer-logo{
    display: none;
  }
  footer .footer-nav{
    display: none;
  }
}