.scroll-btn {
  position: fixed;
  bottom: 50px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  right: 20px !important;
}

.scroll-btn > button {
  background: linear-gradient(118.55deg, #2770f1 -12.3%, #27c1f1 110.19%);
  box-shadow: 0px 2px 8px rgba(39, 112, 241, 0.35);
  border-radius: 8px;
  color: #ffffff;
  height: 48px;
  width: 48px;
}
