$mainColor: #f1f7ff;
$headerColor: #f0f6ff;
$white: #fff;

@mixin centerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

html {
  font-size: 62.5%;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  // background-color: $mainColor;
  // padding: 3rem;
}

.container .list-menu {
  margin-top: 35px;
}

.stats {
  margin: 6.4rem 0;
}

.grid-2-cols {
  display: grid;
  grid-template-columns: 70fr 30fr;
  gap: 12.1rem;
  margin-bottom: 10rem;
  justify-content: center;
  align-items: center;
}

.grid-3-7 {
  grid-template-columns: 30fr 70fr;
}

.metadata-diagram {
  background-color: $white;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.publication-diagram {
  background-color: $white;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding: 18px 24px;
}

.ball_wrap {
  position: relative;
  margin: 150px;
  width: 25.2rem;
  height: 100%;
}

.ball-text {
  color: $white;
  font-size: 1.5rem;
  line-height: 14.14px;
}

.ball-stats {
  font-size: 1.4rem;
  font-weight: 700;
}

.publication-ball {
  background: #a5dfe3;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: -200px;
  z-index: 100;
  @include centerStyle();
}

.tech-ball {
  background: #a5dfe3;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  position: absolute;
  right: -80px;
  bottom: -180px;
  z-index: 100;
  @include centerStyle();
}

.patent-ball {
  background: #a5dfe3;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  position: absolute;
  right: 284px;
  bottom: 74px;
  z-index: 100;
  @include centerStyle();
}

.org-ball {
  background: #a5dfe3;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  position: absolute;
  top: -150px;
  right: -200px;
  z-index: 100;
  @include centerStyle();
}

.expert-ball {
  background: #00affb;
  height: 20rem;
  width: 20rem;
  border-radius: 50%;
  position: absolute;
  top: -11rem;
  z-index: 100;
  @include centerStyle();
}

@keyframes bounce {
  from {
    width: 27rem;
    height: 27rem;
  }
  to {
    width: 32rem;
    height: 32rem;
  }
}

.expert-ball:before {
  content: '';
  display: block;
  position: absolute;
  width: 28rem;
  height: 28rem;
  transform: translate(10%);
  border-radius: 50%;
  background-color: rgba(0, 175, 251, 0.2);
  z-index: 90;
  animation: bounce 5s infinite alternate-reverse;
}

.publication-ball:after {
  content: '';
  display: block;
  border-bottom: 2.65px solid #00affb;
  position: absolute;
  width: 13rem;
  right: -120%;
  top: 5%;
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
}

.org-ball:after {
  content: '';
  display: block;
  border-bottom: 2.65px solid #00affb;
  position: absolute;
  width: 205px;
  left: -195%;
  top: 100%;
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
}

.org-ball:before {
  content: '';
  display: block;
  border-bottom: 2.65px solid #00affb;
  position: absolute;
  width: 16.5rem;
  left: -85%;
  top: 170%;
  -webkit-transform: rotate(-65deg);
  transform: rotate(-65deg);
}

.tech-ball:after {
  content: '';
  display: block;
  border-left: 2.65px solid #00affb;
  position: absolute;
  height: 8.3rem;
  left: -20%;
  top: -50%;
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
}

.patent-ball:after {
  content: '';
  display: block;
  border-left: 2.65px solid #00affb;
  position: absolute;
  height: 8.3rem;
  left: 126px;
  top: 56px;
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
}

.title {
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 600;
  margin-bottom: 2.4rem;
  color: #0a2a63;
}

.description {
  font-size: 1.4rem;
  line-height: 2.2rem;
  margin-bottom: 2.4rem;
  font-weight: 400;
  text-align: justify;
}

.btn-secondary {
  padding: 0 1.6rem;
  border: none;
  font-size: 1.4rem;
  font-weight: 500;
  width: 17rem;
  height: 4.8rem;
  background-color: #42bbff;
  border-radius: 1000px;
  color: $white;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.08);
  cursor: pointer;
  &:hover {
    background-color: #3caff1;
  }
}

.organisations {
  filter: drop-shadow(0px 4px 24px rgba(25, 68, 144, 0.1));
  border-radius: 8px;
  .org-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $headerColor;
    padding: 2rem;
    font-size: 2rem;
    font-weight: 700;
    color: #0a2a63;
  }
}

.stack-icon {
  width: 2.2rem;
  margin-right: 1.1rem;
}

.org-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background-color: $white;
}

.org-block {
  padding: 2rem;
  border-left: 1px solid #acd2e8;
  border-top: 1px solid #acd2e8;
  border-collapse: collapse;
  .logo {
    width: 18rem;
    height: 18rem;
    @include centerStyle();
    margin-bottom: 3rem;
  }
}

.org-name {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  height: 6rem;
  margin-bottom: 8px;
  text-transform: capitalize;
  color: #000000;
  text-decoration: none;
}

.org-num {
  color: #42bbff;
  line-height: 1.9rem;
  font-size: 1.6rem;
  font-weight: 700;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 1024px) {
  .org-grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .publication-ball {
    height: 10rem;
    width: 10rem;
    top: 20px;
    left: -120px;
  }
}

@media all and (max-width: 820px) {
  .grid-2-cols {
    grid-template-columns: 1fr;
  }

  .org-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .metadata-diagram {
    width: 48rem;
    justify-self: center;
  }

  .publication-ball {
    background: #a5dfe3;
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: -130px;
    z-index: 100;
    @include centerStyle();
  }

  .tech-ball {
    background: #a5dfe3;
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    position: absolute;
    right: -80px;
    bottom: -180px;
    z-index: 100;
    @include centerStyle();
  }

  .patent-ball {
    background: #a5dfe3;
    height: 9rem;
    width: 9rem;
    border-radius: 50%;
    position: absolute;
    right: 240px;
    bottom: 74px;
    z-index: 100;
    @include centerStyle();
  }

  .org-ball {
    background: #a5dfe3;
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    position: absolute;
    top: -150px;
    right: -160px;
    z-index: 100;
    @include centerStyle();
  }

  .expert-ball {
    height: 17rem;
    width: 17rem;
    border-radius: 50%;
    position: absolute;
    top: -11rem;
    z-index: 100;
    @include centerStyle();
  }

  .org-ball:after {
    width: 12.5rem;
    left: -115%;
    top: 90%;
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }

  .org-ball:before {
    width: 16.5rem;
    left: -85%;
    top: 170%;
    -webkit-transform: rotate(-65deg);
    transform: rotate(-65deg);
  }

  .tech-ball:after {
    height: 8.3rem;
    left: -20%;
    top: -50%;
    -webkit-transform: rotate(-50deg);
    transform: rotate(-50deg);
  }

  .patent-ball:after {
    position: absolute;
    height: 9.5rem;
    left: 126px;
    top: 35px;
    -webkit-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }

  .publication-ball:after {
    width: 7rem;
    right: -50%;
    top: 0%;
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
}

@media all and (max-width: 480px) {
  .org-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .metadata-diagram {
    width: 34rem;
  }

  .org-block .logo {
    width: fit-content !important;
  }

  .expert-ball {
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    position: absolute;
    top: -11rem;
    left: -6rem;
    z-index: 150;
    @include centerStyle();
  }

  @keyframes bounce {
    from {
      width: 18rem;
      height: 18rem;
    }
    to {
      width: 25rem;
      height: 25rem;
    }
  }

  .patent-ball {
    background: #a5dfe3;
    height: 8rem;
    width: 8rem;
    right: 110px;
    bottom: 100px;
  }

  .patent-ball:after {
    height: 8.5rem;
    left: 100px;
    top: 50px;
    -webkit-transform: rotate(-50deg);
    transform: rotate(-50deg);
  }

  .tech-ball {
    height: 9.5rem;
    width: 9.5rem;
    right: -100px;
    bottom: -180px;
  }

  .tech-ball:after {
    height: 8.3rem;
    left: 20%;
    top: -80%;
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }

  .org-ball:before {
    width: 15rem;
    left: -50%;
    top: 170%;
    -webkit-transform: rotate(-75deg);
    transform: rotate(-75deg);
  }

  .publication-ball {
    height: 9.5rem;
    width: 9.5rem;
    top: 20px;
    left: -150px;
    z-index: 100;
  }
}
