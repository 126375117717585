.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  margin-top: -10rem;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem;
}
#not-found-page.content {
  min-height: 80vh !important;
}
#not-found-page {
  .h1{
    font-size: 32px;
    line-height: 32px;
    color: #0A2A63;
    margin-bottom: 24px;
  }
  .text-desc {
    font-size: 16px;
    line-height: 24px;
    color: #666666;
  }
  button {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding: 14px 44px;

    a{
      color: #FFFFFF;
      text-decoration: none;
    }
  }
}