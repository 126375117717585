#content-expert{
  font-family: "Inter";
  font-style: normal;
  background-color: #f5f8ff;
  padding: 2rem;
  flex-grow: 1;
  min-height: 100vh;
  width: 75%;
}

#content-expert .header-title{
  padding: 0;
  margin: 0;
}

#content-expert .header-title .avatar{
  position: inherit;
  margin: 0;
  width: 120px;
  height: 120px;
  padding: 12px;
  background-color: white;
  box-shadow: 0px 4px 24px rgba(21, 74, 137, 0.1);
  border-radius: 8px;
}
#content-expert .header-title .avatar .avatar-img{
  object-fit: contain;
  border-radius: 6px !important;
}
#content-expert .header-title .infor-expert{
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 8px 8px 0px;
  height: 96px;
  background-image: url('/public/images/background/bg_expert_infor.svg');
}
#content-expert .header-title .infor-expert h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
}
#content-expert .header-title .infor-expert .span-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: white;
}
#content-expert .header-title .infor-expert .label-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: white;
}

#content-expert .bar-expert{
  border-color: #76cde7 !important;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #D7E7F3;
  background-color: #ffffff;
}
#content-expert .bar-expert .summary-expert h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: black;
}

#content-expert .bar-expert .summary-expert .summary-expert-items{
  list-style: none;
  margin: 0;
  padding: 0;
}

#content-expert .bar-expert .summary-expert .summary-expert-items .title-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  min-width: 60px;
}

#content-expert .bar-expert .summary-expert .summary-expert-items .value-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #666666;
}

#content-expert .bar-expert .study-expert{
  border-color: #76cde7 !important;
}

#content-expert .bar-expert .study-expert h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: black;
}

#content-expert .bar-expert .study-expert .title-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
}

#content-expert .bar-expert .bio-expert{
  border-color: #76cde7 !important;
}

#content-expert .bar-expert .bio-expert h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: black;
}

#content-expert .bar-expert .bio-expert .span-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

#content-expert .tab-content{
  padding: 12px;
  border-color: #76cde7 !important;
  background: #FFFFFF;
  border-radius: 8px;
}

#content-expert .tab-content .btn-tab-content{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  border: none;
  border-radius: 0;
  width: 180px;
  height: 48px;
  color: #0A2A63;
  background: #DFF3FF;
}

#content-expert .tab-content .btn-tab-content:hover{
  color: white;
  background: linear-gradient(118.55deg, #2770F1 -12.3%, #27C1F1 110.19%);
}

#content-expert .tab-content .btn-tab-content-active{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: white;
  border: none;
  border-radius: 0;
  width: 180px;
  height: 48px;
  background: linear-gradient(118.55deg, #2770F1 -12.3%, #27C1F1 110.19%);
}

#content-expert .tab-content .tab-content-border{
  border-bottom: 3px solid #bce6f3;
}

#content-expert .tab-content .technology-tab{
  background: white;
}

#content-expert .tab-content .technology-tab span{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}
#content-expert .tab-content .technology-tab .technology-tab-infor img{
  width: 750px;
  height: 244px;
}

#content-expert .tab-content .track-tab{
  padding-bottom: 15px;
  border-bottom: 1px solid #cdeaf3;
}

#content-expert .tab-content .track-tab h6{
  margin-bottom: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #0A2A63;
}

#content-expert .tab-content .track-tab h6:hover{
  cursor: pointer;
  text-decoration-line:underline;
  text-decoration-color:#0A2A63;
}

#content-expert .tab-content .track-tab .linked span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #666666;
}

#content-expert .tab-content .track-tab span{
  margin-top: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
}

#content-expert .tab-content .ex-tab{
  padding-bottom: 15px;
  border-bottom: 1px solid #cdeaf3;
}

#content-expert .tab-content .ex-tab .ex-item .ex-item-icon{
  margin-right: 10px;
  font-size: 20px;
  color: #27a9f1;
}
#content-expert .tab-content .ex-tab .ex-item .ex-item-title{
  width: 140px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px; 
  color: #333333;
}
#content-expert .tab-content .ex-tab .ex-item .ex-item-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px; 
  color: #666666;
}

#content-expert .similar-expert{
  border: none;
  padding: 12px;
}
#content-expert .similar-expert .similar-expert-title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #0A2A63;
}

#content-expert .similar-expert .similar-expert-sort h5{
  margin-bottom: 0;
  font-size: 15px;
  color: #666666;
}

#content-expert .similar-expert .similar-expert-sort select{
  margin-right: 2px;
  cursor: pointer;
  font-size: 15px;
  width: 120px;
  background-color: transparent;
  color: #27c1f1;
}

#content-expert .similar-expert .similar-expert-sort button span {
  color: #27c1f1;
}

#content-expert .similar-expert .similar-expert-item{
  width: 230px;
  border-color: #76cde7 !important;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 1px;
}

#content-expert .similar-expert .similar-expert-item:hover{
  padding: 0px;
  border: 1px solid #87d2e9;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px;
}

#content-expert .similar-expert .similar-expert-item .avatar{
  position: inherit;
  margin: 0;
  width: 120px;
  height: 120px;
  padding: 12px;
  background-color: white;
  box-shadow: 0px 4px 24px rgba(21, 74, 137, 0.1);
  border-radius: 8px;
}

#content-expert .similar-expert .similar-expert-item .avatar .avatar-img{
  object-fit: contain;
  border-radius: 6px !important;
}

#content-expert .similar-expert .similar-expert-item .name{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #0A2A63;
}

#content-expert .similar-expert .similar-expert-item .organisation{
  margin-left: 25px;
  margin-right: 25px;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}

#content-expert .similar-expert .similar-expert-item .h-index{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #666666;
}

@media (max-width: 65em) {
  #content-expert {
    width: 100% !important;
  }
  #content-expert .bar-expert {
    display: block !important;
  }
  #content-expert .bar-expert>div:not(:first-child){
    margin-left: 0 !important;
    border-left: none !important;
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    padding: 10px 0 !important;
  }
  
}

@media (max-width: 42em) {
  
}