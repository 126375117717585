@media (max-width: 65em) {
    #item-organizations {
        .infor{
            display: block!important;
            .note {
                border-left: none !important;
                margin: 0px;
            }
        }
        .infor-header{
            flex-direction: column !important;
        }
    }
}
@media (max-width: 42em) {
  #item-organizations {
        .related-item{
            display: block!important;
        }
        .related-item>div {
            width: 100% !important;
            margin-bottom: 20px;
        }
    }
}