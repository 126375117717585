$fontInter: 'Inter';
$fontNormal: normal;
$white: #ffffff;
$black: #000000;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.top {
  padding: 1rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}