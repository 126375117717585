#content-tech {
  font-family: 'Inter';
  font-style: normal;
  background-color: #f5f8ff;
  padding: 2rem;
  flex-grow: 1;
  min-height: 100vh;
}

#content-tech .header-title {
  padding: 0;
  margin: 0;
}

#content-tech .header-title .avatar {
  position: inherit;
  margin: 0;
  width: 120px;
  height: 120px;
  padding: 12px;
  background-color: white;
  box-shadow: 0px 4px 24px rgba(21, 74, 137, 0.1);
  border-radius: 8px;
}
#content-tech .header-title .avatar .avatar-img {
  object-fit: contain;
  border-radius: 6px !important;
}
#content-tech .header-title .info-tech {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 8px 8px 0px;
  height: 96px;
  background-image: url('/public/images/background/bg_expert_infor.svg');
}
#content-tech .header-title .info-tech h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  text-transform: uppercase;
}
#content-tech .header-title .info-tech .span-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: white;
}
#content-tech .header-title .info-tech .label-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: white;
}

#content-tech .similar-expert {
  border: none;
  padding: 12px;
}
#content-tech .similar-expert .similar-expert-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #0a2a63;
}

#content-tech .similar-expert .similar-expert-sort h5 {
  margin-bottom: 0;
  font-size: 15px;
  color: #666666;
}

#content-tech .similar-expert .similar-expert-sort select {
  margin-right: 2px;
  cursor: pointer;
  font-size: 15px;
  width: 120px;
  background-color: transparent;
  color: #27c1f1;
}

#content-tech .similar-expert .similar-expert-sort button span {
  color: #27c1f1;
}

#content-tech .similar-expert .similar-expert-item {
  border-color: #76cde7 !important;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
}

#content-tech .similar-expert .similar-expert-item:hover {
  border: 1px solid #87d2e9;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px;
}

#content-tech .similar-expert .similar-expert-item .ava {
  overflow: hidden;
  text-align: center;
}

#content-tech .similar-expert .similar-expert-item .ava img {
  margin: 12px auto;
  width: 96px;
  height: 96px;
  background-color: #fff;
  box-shadow: 0 4px 24px rgb(21 74 137 / 10%);
  padding: 12px;
}

#content-tech .similar-expert .similar-expert-item .name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #0A2A63;
  min-height: 44px;
}

#content-tech .similar-expert .similar-expert-item .expert-detail-info {
  margin-top: 16px;
} 

#content-tech .similar-expert .similar-expert-item .organisation {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
  margin-top: 8px;
}

#content-tech .similar-expert .similar-expert-item .h-index {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #666666;
}

#content-tech .similar-expert .MuiPagination-ul {
  justify-content: center;
}

#content-tech .similar-expert .expert-detail-info {
  font-size: 13px;
}