#org-detail .org-img {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(21, 74, 137, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
}

#org-detail .org-img img {
  /* max-width: 96px;
  max-height: 96px; */
  width: -webkit-fill-available;
}

#org-detail .org-name {
  border-radius: 0px 8px 8px 0px;
  background-image: url('/public/images/background/bg_expert_infor.svg');
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  padding: 34px 24px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin: 0;
}

#org-detail .org-name h2 {
  text-transform: uppercase;
}
#org-detail .org-name span {
  font-size: 14px;
}

#org-detail .content-detail {
  padding: 40px 30px;
  background: #ffffff;
  border: 1px solid rgba(61, 86, 178, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: space-between;
  margin-top: 19px;
  margin-bottom: 19px;
  font-family: 'Inter';
}

#org-detail .content-detail .desc {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: justify;
  color: #333333;
}

#org-detail .content-detail .content-tab {
  margin-top: 40px;
}

#org-detail .MuiTabs-flexContainer button {
  background: #dff3ff;
  color: #0a2a63;
  font-size: 14px !important;
  font-family: 'Inter';
}

#org-detail .MuiTabs-flexContainer button:first-child {
  border-radius: 6px 0px 0px 0px;
}

#org-detail .MuiTabs-flexContainer button:last-child {
  border-radius: 0px 6px 0px 0px;
}

#org-detail .MuiTabs-flexContainer button.Mui-selected {
  background: linear-gradient(118.55deg, #2770f1 -12.3%, #27c1f1 110.19%);
  color: #ffffff;
}

#org-detail .info {
  font-size: 14px;
  font-weight: 500;
  padding: 24px 0px;
}

#org-detail .info .info-item {
  padding: 5px 0px;
}

#org-detail .info .info-item span {
  color: #666666;
}

#org-detail .expert-list .sort-bar {
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 500;
}

#org-detail .expert-list .sort-bar .text-blue {
  font-weight: 700;
  color: #42bbff;
}

#org-detail .expert-list .sort-bar select {
  background: inherit;
}

.exp-item {
  padding: 20px;
  margin-bottom: 19px;
  font-family: 'Inter';
}

.exp-item .exp-avatar {
  width: fit-content;
}

.exp-item .exp-avatar img {
  padding: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(21, 74, 137, 0.1);
  border-radius: 8px;
}

.exp-item .MuiAvatarGroup-root {
  justify-content: left;
}
.exp-item .MuiAvatarGroup-root > div {
  margin-right: 16px;
}

.exp-item .exp-info-left {
  border-right: 1px solid #d7e7f3;
}

.exp-item .exp-name a {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #0a2a63;
  text-decoration: none;
}

.exp-item .exp-location {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
  margin-top: 16px;
}

.exp-item .exp-expertise {
  list-style-type: none;
  padding: 0;
  margin-top: 16px;
}

.exp-item .exp-expertise > li {
  text-transform: capitalize;
  display: inline-block;
  background: #dff3ff;
  border-radius: 1000px;
  padding: 6px 12px;
  margin: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #0a2a63;
  margin-left: 0px;
}

.exp-item .exp-similar {
  margin-top: 16px;
}

.exp-item .exp-similar > p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}

.exp-item .exp-info-right ul {
  list-style-type: none;
  padding: 0px;
  margin: 0;
}

.exp-item .exp-info-right ul li {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 16px;
}

.exp-item .exp-info-right ul li img {
  margin-right: 10px;
}

.exp-item .exp-info-right ul li span {
  margin-right: 16px;
}

.exp-item .exp-info-right ul li span:first-child {
  color: #333333;
}

.exp-item .exp-info-right ul li span:last-cz {
  color: #666666;
}
#org-detail .org-chart .col-5 {
  width: 49%;
}
#org-detail .org-chart > div {
  padding: 40px 30px;
  box-sizing: border-box;
}
#org-detail .org-chart .title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

#org-detail .org-chart .exp-name {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #0a2a63;
  text-decoration: none;
}

#org-detail .org-chart .bar {
  width: 401px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  color: #ffffff;
  padding: 2px 8px;
}

#org-detail #prominent-profiles .papers-pagination {
  margin-top: 20px;
}

#org-detail #prominent-profiles > div > div {
  margin-bottom: 10px;
}

#org-detail #prominent-profiles .exp-avatar {
  width: fit-content;
  margin-right: 5px;
}

#org-detail .MuiBox-root {
  padding: 0px;
}
