#about-us .header {
  min-height: 20vh;
  background-image: url('/public/images/about-us/about-us-bg.svg');
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
}
#about-us .header h1 {
  flex: 1;
}
#about-us h2 {
  color: #0a2a63;
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
}

#about-us #about-story .flex-item {
  width: 45%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #333333;
}

#about-us #about-function .flex-item {
  width: 30%;
  color: #333333;
  font-size: 14px;
  line-height: 22px;
}

#about-us #about-function .sub-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

#about-us #about-function .flex-item .text-focus {
  font-weight: 600;
  text-align: justify;
}

#about-us #about-function .list-tech {
  font-size: 14px;
}

#about-us #about-team h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 16px;
  background: #dff3ff;
  border-radius: 8px;
}

#about-us #about-team #lead-team .flex-item {
  width: 49%;
}
#about-us #about-team #partner-team .flex-item {
  width: 31%;
}
#about-us #about-team #lead-team .flex-item,
#partner-team .flex-item {
  text-align: center;
  background: #ffffff;
  border: 1px solid #d7e7f3;
  border-radius: 6px;
  padding: 40px 24px;
}

#about-us #about-team .member-name {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #0a2a63;
}

#about-us #about-team .member-avatar {
  width: 164px;
  height: 164px;
  padding: 12px;
  box-shadow: 0px 4px 24px rgba(21, 74, 137, 0.1);
  border-radius: 8px;
  margin: 0px auto;
}

#about-us #about-team .member-avatar > img {
  height: 100%;
  display: block;
  object-fit: fill;
  margin: 0px auto;
}

#about-us #about-team #lead-team .profile-title,
#partner-team .profile-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}

#about-us #about-team #project-team > div {
  padding: 1.5rem 0;
}
#about-us #about-team #project-team .profile-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}

#about-us #about-team #project-team .bio {
  font-size: 13px;
  line-height: 20px;
  text-align: justify;
  color: #333333;
}

#about-us #about-team #project-team .flex-item:first-child {
  width: 19%;
}

#about-us #about-team #project-team .flex-item:last-child {
  width: 80%;
}

#about-us #about-team #project-team .border-end {
  border-bottom: 2px solid #c4e2f8;
  border-right: none !important;
}

#about-us #about-team #project-org {
  background: #ffffff;
  border: 1px solid #d7e7f3;
  border-radius: 6px;
  padding: 40px 0px;
  padding-right: 10px;
}

#about-us #about-team #project-org img {
  object-fit: fill;
}

#about-us #contact #banner {
  min-height: 30vh;
  background-image: url('/public/images/about-us/contact-banner.png');
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#about-us #contact #banner p {
  flex: 1;
}

.text-justify {
  text-align: justify;
}

@media all and (max-width: 540px) {
  #about-us .d-flex {
    display: block !important;
    width: 100% !important;
    text-align: center;
  }
  #about-us .flex-item {
    width: 100% !important;
  }
  #about-us #about-story .flex-item img {
    display: none;
  }
  #about-us #about-team #project-team .profile-title {
    text-align: center;
  }
  #about-us #about-team #project-team .member-name {
    text-align: center;
  }
  #about-us .org-avatar .flex-item {
    margin-bottom: 10px;
  }
  #about-us .org-avatar .flex-item img {
    width: -webkit-fill-available;
  }
  #about-us #contact {
    display: none !important ;
  }
  #about-us #project-org > div {
    display: block !important;
  }
}
@media all and (max-width: 912px) {
  #about-us #about-function img {
    width: -webkit-fill-available;
  }
  #about-us #about-team #project-org img {
    /* width: -webkit-fill-available; */
    height: auto;
    width: 50%;
  }
}
