header {
  width: 100%;
  height: 840px;
}
@media screen and (max-width: 480px){
  header {
    height: 440px;
  }
}

header .ellipse-light {
  position: absolute;
  width: 212px;
  height: 212px;
  left: 250px;
  top: 287px;
  background: rgba(46, 170, 255, 0.5);
  filter: blur(80px);
  z-index: 1;
}
header .ellipse-dark {
  position: absolute;
  width: 134px;
  height: 134px;
  left: 1090px;
  top: 333px;
  background: rgba(238, 189, 64, 0.35);
  filter: blur(80px);
  z-index: 1;
}

header .background-bg {
  background-image: url('/public/images/background/bg.svg');
}

header .background-bg1 {
  background-image: url('/public/images/background/bg1.svg');
}

header .background-shadow {
  background-image: url('/public/images/background/shadow.svg');
}

header .background {
  position: absolute;
  top: 0;
  left: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
}
header .container-fluid {
  z-index: 1;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 40px;
  padding-right: 40px;
}

header .btn-menu {
  margin: 0;
  padding: 0;
}

header .navbar-brand {
  z-index: 1;
}
header .navbar-brand .logo {
  gap: 12px;
  cursor: pointer;
  width: 211px;
}

header .offcanvas {
  --bs-offcanvas-width: '0px';
}

header .btn-container {
  z-index: 1;
  display: flex;
  gap: 10px;
}

header .login-btn {
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 16px;
  color: white;
  border: 1px solid #ffffff;
}

header .login-btn:hover {
  color: #eebd40 !important;
}

header .contact-us {
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 16px;
  color: white;
  box-shadow: 0px 2px 8px rgba(238, 189, 64, 0.4);
}
header .contact-us a {
  color: white;
  text-decoration: none;
  display: block;
}
header .contact-us:hover {
  color: white !important;
  background-color: #eebd40 !important;
  border-color: #eebd40 !important;
}
header nav {
  margin-right: 34px;
}
header nav .navbar-nav .nav-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: 'Inter', sans-serif !important;
}
header nav .nav-link {
  /* width: 70px !important; */
  font-weight: 500 !important;
  color: white !important;
}
header nav .nav-item:hover .nav-link {
  color: white !important;
  font-weight: 700 !important;
  border-bottom: 2px solid white;
  font-weight: bold !important;
}
header nav .nav-item .nav-active {
  color: #42bbff !important;
  font-weight: 700 !important;
  border-bottom: 2px solid #42bbff !important;
  font-family: 'Inter', sans-serif !important;
}

.fixed-header {
  background-image: url('/public/images/background/bg-header.svg');
  width: 100%;
  background-color: #0a2a63;
  display: block;
  height: auto;
  background-size: cover;
}

.header {
  height: auto;
}

@media all and (max-width: 768px) {
  .ellipse-light,
  .ellipse-dark {
    left: inherit !important;
  }
}

@media screen and (min-width: 480px) and (max-width: 979px) {
  header .background-bg {
    background-image: url('/public/images/background/bg.svg');
  }
  header .background-bg1 {
    background-image: url('/public/images/background/bg1.svg');
  }
  header .background-shadow {
    top: 0px;
    background-image: url('/public/images/background/shadow.svg');
  }
  header .navbar-tabnet-mobile {
    margin: 0 !important;
    display: block !important;
  }
  header .navbar-destop {
    display: none;
  }
  header .fixed-header {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  header .container-fluid {
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }
  header nav .nav-link {
    justify-content: start !important;
    font-size: 10px;
  }
  header nav .navbar-nav .nav-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  header .navbar-brand .logo {
    width: 176.2px !important;
  }
  header .login-btn {
    display: none;
  }
  /* header .contact-us {
    width: 103px !important;
    height: 32px !important;
  } */
}

@media screen and (max-width: 480px) {
  header .background-bg {
    background-image: url('/public/images/background/bgMobile.svg');
  }

  header .background-bg1 {
    background-image: url('/public/images/background/bg1Mobile.svg');
  }

  header .background-shadow {
    top: 100px;
    background-image: url('/public/images/background/shadowMobile.svg');
  }
  header .navbar-tabnet-mobile {
    margin: 0 !important;
    display: block !important;
  }
  header .navbar-destop {
    display: none;
  }
  header .fixed-header {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  header .container-fluid {
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
  header nav .nav-link {
    justify-content: start !important;
    font-size: 10px;
  }
  header nav .navbar-nav .nav-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  header .navbar-brand .logo {
    width: 140.96px !important;
  }
  header .login-btn {
    display: none;
  }
  /* header .contact-us {
    width: 103px !important;
    height: 32px !important;
  } */
}

.offcanvas-body .navbar-nav li {
  padding: 20px 0px !important;
}

.offcanvas-body .navbar-nav li a {
  font-size: 24px !important;
}
