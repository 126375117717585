.firm-slogan {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.9rem;
  color: #0a2a63;
  margin-bottom: 2.4rem;
}

.firm-red-text {
  color: #c71324;
  margin-bottom: 2.4rem;
  display: inline-block;
  font-size: 1.4rem;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 2.4rem;
  cursor: pointer;
}

.firm-url:hover {
  color: #910c1a;
  font-weight: 600;
}

.firm-des {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 1.6rem;
}

.icon-note {
  font-size: 17px;
  color: #27c1f1;
}

.text-note {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #666666;
}

.firm-section {
  margin-bottom: 4.5rem;
}

.heading-icon {
  font-size: 36px;
  color: #27c1f1;
  margin-right: 8px;
}

.firm-heading {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  vertical-align: middle;
  color: #333333;
  display: flex;
  align-items: center;
  margin-bottom: 21.5px;
}

.firm-location {
  min-width: 36rem;
}

.grey-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #666666;
}

.contact-text {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  line-height: 2.2rem;
  color: #333333;
  margin-bottom: 8px;
}

.bullet {
  position: absolute;
  left: -20px;
  top: 2px;
}

.provider-container {
  display: flex;
  gap: 2.4rem;
  justify-content: center;
}

.provider-detail {
  padding: 12px;
  background: #ffffff;
  border: 1px solid rgba(61, 86, 178, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: space-between;
  margin-top: 19px;
  margin-bottom: 19px;
  width: 32%;
  min-width: 0;
}

.provider-logo {
  width: 4.8rem;
}

.provider-name {
  color: #0a2a63;
}

.provider-note {
  background-color: #f0f6ff;
  padding: 13px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.provider-btn {
  color: #ffffff;
  // width: 100%;
  font-size: 1.2rem;
  padding: 9px 16px;
  border: none;
  background-color: #42bbff;
  border-radius: 4px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // gap: 6px;
}

.portfolio-img-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.4rem;
}

.portfolio-img {
  width: 100%;
  border-radius: 8px;
}

.ml-0 {
  margin-left: 0;
}

.review-block {
  position: relative;

  .review-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .review-quote {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 2.4rem;
  }
}

.custom-tooltip {
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #dddada;
  color: #333333;
  top: -50;
}

.review-block:after {
  content: '';
  height: 110%; //You can change this if you want smaller/bigger borders
  width: 1px;

  position: absolute;
  right: 6px;
  top: -20px; // If you want to set a smaller height and center it, change this value

  background-color: #dee2e6; // The color of your border
}

@media (max-width: 768px) {
  .portfolio-img-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 375px) {
  .portfolio-img-container {
    grid-template-columns: 1fr;
  }
}
