$fontInter: 'Inter';
$fontNormal: normal;
$white: #ffffff;
$black: #000000;
$blue:#0A2A63;
$grey:#333333;
$greyLight:#666666;

.similar-expert-avatar {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.detail-info-2-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 2rem;
  align-items: center;
  justify-content: center;
}


.content-firms {
  font-family: $fontInter;
  font-style: $fontNormal;
  background-color: #f5f8ff;
  padding: 2rem;
  flex-grow: 1;
  min-height: 100vh;
  width: 80%;
}

.content-firms h3 {
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: $blue;
}

.content-firms .slogan {
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: $grey;
}

.content-firms .rating-text{
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: $blue;
}

.content-firms .rating-viewer{
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 400;
  font-size: 13px;
  color: $blue;
  order: 2;
  line-height: 13px;
}

.content-firms .box-view{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  gap: 4px;
  
  width: 153px;
  height: 115.5px;
  
  /* Solid/Blue 2 #42BBFF */
  border-radius: 6px;
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.content-firms .box-view .box-view-label{
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.content-firms .box-view .box-view-icon{
  font-size: 20px;
}

#item-firms .money-text{
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: $greyLight;
}

#item-firms .firms-footer{
  border-top: 1px solid #D7E7F3;
}

#item-firms .infor-firms{
  width: 260px;
  height: 153px;
  border-right: 1px solid #D7E7F3;
}

#item-firms .chart-firms{
  padding: 20px;
  width: 763px;
  height: 153px;
  border-right: 1px solid #D7E7F3;
}

#item-firms .chart-firms .chart-firms-text{
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: $grey;
}

#item-firms .chart-firms .chart-firms-desc{
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  background: linear-gradient(261.49deg, #154A89 -0.5%, #0E3C82 101.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

#item-firms .text-firms .text-firms-content{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: $fontInter;
  font-style: $fontNormal;
  color: $greyLight;
}

#item-firms .view-firm{
  border-left: 1px solid #D7E7F3;
}

.content-firms .filter h5 {
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 18px;
  color: #333333;
}

.content-firms .filter label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}
.content-firms .bar {
  background-color: $white;
  border-color: #c4e2f8 !important;
}
.content-firms .bar button {
  height: 81.59px;
  border-radius: 0%;
  border-color: #c4e2f8 !important;
  --bs-btn-bg: $white;
}
.content-firms .bar button span {
  color: #27c1f1;
}
.content-firms .bar .related div {
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  // width: 210px;
}
.content-firms .bar .related h6 {
  font-family: $fontInter;
  font-style: $fontNormal;
  font-size: 13px;
  color: #666666;
}
.content-firms .bar .related label {
  font-family: $fontInter;
  font-style: $fontNormal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.content-firms .sort h5 {
  margin-bottom: 0;
  font-size: 15px;
  color: #666666;
}

.content-firms .sort select {
  margin-right: 2px;
  cursor: pointer;
  font-size: 15px;
  width: 150px;
  background-color: transparent;
  color: #27c1f1;
}
.content-firms .sort button span {
  color: #27c1f1;
}

#item-firms {
  border-style: hidden;
  padding: 0px !important;
  background-color: $white;
}

#item-firms:hover {
  border-style: solid;
  border-color: #27c1f1;
  box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
}

#item-firms .infor .img {
  height: 64px;
}
#item-firms .infor .img img {
  object-fit: contain;
  height: 100%;
}
